@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Rubik', sans-serif;
}

.blog h1 {
    font-size: 2.25rem;
}

.blog h2 {
    font-size: 2rem;
}

.blog li {
    list-style: disc;
    margin-left: 1rem;
}

.blog em {
    font-size: 1.5rem;
}

.blog p, li {
    font-size: 1.3rem;
}

.blog blockquote {
    margin-top: 1rem;
    margin: 0.5rem;
    padding: 15px;
    font-style: normal;
    border-left: white 2px solid;
    background: none;
    text-align: left;
}

.blog a {
    text-decoration: underline;
    color: #808080;
}
